<template>
  <b-card title="">
    <template #header>
      <div>

        <b-form-input
          v-model="name"
          type="text"
          placeholder="Stage name"
          @input="emitChanges()"
        />

      </div>
      <div>
        <b-button
          variant="gradient-danger"
          class="btn-icon"
          @click="removeStage()"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
    </template>

    <b-row>

      <b-col md="3">
        <b-form-group
          label="Event"
        >
          <div class="event-form-ctr">

            <div class="category-select-cont">
              <v-select
                v-model="category"
                label="title"
                :options="categoryTypes"
                @input="emitChanges()"
              />
            </div>

            <div class="category-select-cont">
              <b-form-input
                v-model="event"
                type="text"
                placeholder="Please add event type"
                @input="emitChanges()"
              />
            </div>

            <ClixrayTimeFrame
              v-model="eventTs"
              @input="emitChanges()"
            />
          </div>

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group
          label=""
        >
          <label>
                  &nbsp;
          </label>
          <b-form-input
            v-model="event"
            type="text"
            placeholder="Please add event type"
            @input="emitChanges()"
          />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group
          label=""
        >
          <label>
                  &nbsp;
          </label>
          <ClixrayTimeFrame
            v-model="eventTs"
            @input="emitChanges()"
          />
        </b-form-group>
      </b-col> -->

      <b-col cols="12">
        <b-form-group
          label="Event Properties"
        >
          <EventProperties
            v-model="customFields"
            @item-deleted="removeCustomField"
            @item-added="addCustomField"
            @item-key-changed="customFieldKeyChange"
            @item-value-changed="customFieldValueChange"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="stageIndex != 0"
        cols="12"
      >
        <b-form-group>
          <b-form-checkbox
            v-model="negate"
            @change="emitChanges()"
          >
            Negate event match
          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col
        cols="4"
      >

        <b-form-group>
          <label>
            Apply Tags
          </label>
          <v-select
            v-model="tags"
            taggable
            multiple
            push-tags
            @input="emitChanges()"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-card>

</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  BFormGroup, BFormInput, BRow, BCol, BFormCheckbox, BCard, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import ClixrayTimeFrame from '@/modules/core/components/ClixrayTimeFrame.vue'
import EventProperties from '@/modules/journey/components/add-edit/EventProperties.vue'

export default defineComponent({
  components: {
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    ClixrayTimeFrame,
    EventProperties,
    BFormCheckbox,
    BCard,
    BButton,
    vSelect,
  },

  props: {
    stageIndex: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default() { return { name: '' } },
      required: false,
    },
  },
  setup(props, context) {
    const categoryTypes = ref([
      { title: 'Platform Events', id: 'platform' },
      { title: 'Email Events', id: 'email' },
    ])
    const name = ref('')
    const event = ref('')
    const category = ref(categoryTypes.value[0])
    const eventTs = ref('')
    const tags = ref([])
    const negate = ref(false)
    const customFields = ref([{ field: '', value: '' }])

    let inputChanged = false

    watch(() => props.value, input => {
      if (inputChanged) {
        return
      }

      negate.value = input.negate
      name.value = input.name
      if (input.category) {
        category.value = categoryTypes.value.find(item => item.id === input.category)
      }
      event.value = input.events && input.events.length === 1 ? input.events[0] : ''
      eventTs.value = input.event_ts
      tags.value = input.tags
      if (input.custom_fields && input.custom_fields.length > 0) {
        customFields.value = input.custom_fields
      }
    }, { immediate: true })

    const emitChanges = () => {
      const modelValue = {
        name: name.value,
        events: [event.value],
        event_ts: eventTs.value,
        tags: tags.value,
        negate: negate.value,
        category: category.value.id,
        custom_fields: customFields.value ? customFields.value
          .filter(item => item.field && item.value)
          .filter(item => item.field.trim() !== '' && item.value.trim() !== '') : [],
      }
      inputChanged = true
      context.emit('input', modelValue)
    }

    const addCustomField = () => {
      customFields.value.push({ field: '', value: '' })
      emitChanges()
    }

    const removeCustomField = index => {
      customFields.value.splice(index, 1)
      emitChanges()
    }

    const customFieldKeyChange = ({ index, value }) => {
      customFields.value[index].field = value
      emitChanges()
    }

    const customFieldValueChange = ({ index, value }) => {
      customFields.value[index].value = value
      emitChanges()
    }

    return {
      name,
      event,
      eventTs,
      tags,
      negate,
      customFields,
      emitChanges,
      customFieldKeyChange,
      customFieldValueChange,
      addCustomField,
      removeCustomField,
      category,
      categoryTypes,

    }
  },
  methods: {
    removeStage() {
      this.$emit('removeStage')
    },
  },
})
</script>

<style lang="scss" scoped>
 .event-form-ctr {
   display: flex;
   align-items: flex-start;
   gap: 5px;
   min-width: 535px
 }
 .category-select-cont {
   min-width: 230px;
 }
</style>
