<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="Name"
          label-for="jouenry-name"
        >
          <b-form-input
            id="jouenry-name"
            v-model="journeyName"
            type="text"
            placeholder="Please add event type"
            @input="syncChanges()"
          />
        </b-form-group>
      </b-col>
      <b-col md="6" />

      <b-col md="12">
        <b-tabs
          v-model="tabIndex"
          nav-class="stages-list"
          vertical
          nav-wrapper-class="nav-vertical"
        >
          <b-tab
            v-for="(item, index) in stages"
            :key="index"
            :active="index === 0"
            :title="item.name"
          >
            <EventStage
              v-model="stages[index]"
              :stage-index="index"
              @input="syncChanges()"
              @removeStage="removeStage(index)"
            />
          </b-tab>

          <template #tabs-end>

            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              pill
              size="sm"
              @click.prevent="addStage()"
            >
              Add stage
            </b-button>

            <!-- <b-nav-item
              role="presentation"
              href="#"
            >

              <b>+</b>
            </b-nav-item> -->
          </template>
        </b-tabs>
      </b-col>

      <b-col md="12">
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="save(false)"
          >
            Save
          </b-button>
            &nbsp; &nbsp;
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="save(true)"
          >
            Save and close
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  BTabs, BTab, BFormInput, BFormGroup, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import EventStage from '@/modules/journey/components/add-edit/EventStage.vue'

export default defineComponent({
  components: {
    BTabs, BTab, EventStage, BFormInput, BFormGroup, BRow, BCol, BButton, BCard,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default() {
        return {
          name: '',
          stages: [{
            name: 'Stage 1',
            category: 'platform',
            events: ['login'],
            tags: ['testtag'],
            event_ts: { operator: '$date_between', value1: '2021-07-18', value2: '2021-07-31' },
            custom_fields: [{ field: 'key1', value: 'test value1' }, { field: 'key 2', value: 'test value 2' }],
          }],
        }
      },
      required: false,
    },
  },
  setup(props, context) {
    const modleValue = ref(props.value)
    const journeyName = ref(modleValue.value.name)
    const stages = ref(modleValue.value.stages)
    const tabIndex = ref(0)

    watch(() => props.value, newValue => {
      journeyName.value = newValue.name
      if (!newValue.stages || newValue.stages.length === 0) {
        stages.value = [{ name: 'Stage 1', events: [] }]
      } else {
        stages.value = newValue.stages.map((stg, i) => ({ ...stg, name: stg.name ? stg.name : `Stage ${i + 1}` }))
      }
    }, { immediate: true })

    const addStage = () => {
      stages.value.push({ name: `Stage ${(stages.value.length + 1)}`, events: [] })
      tabIndex.value = (stages.value.length - 1)
    }

    const removeStage = index => {
      stages.value.splice(index, 1)
    }

    const syncChanges = () => {
      const newModle = { name: journeyName.value, stages: stages.value }
      context.emit('input', newModle)
      return newModle
    }

    const save = close => {
      const modle = syncChanges()
      context.emit('save', { data: modle, close })
    }

    return {
      journeyName, stages, addStage, tabIndex, removeStage, syncChanges, save,
    }
  },

})
</script>

<style scoped>

</style>
