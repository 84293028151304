<template>

  <div>
    <b-button
      :id="triggerId"
      tabindex="0"
      class="dropdown-toggle"
      variant="outline-secondary"
    >
      {{ displayLable }}
    </b-button>

    <b-popover
      ref="popover"
      :target="triggerId"
      triggers="click"
      placement="bottom"
      custom-class="clx-timeframe"
      :container="'popover-container'+ triggerId"
    >
      <b-tabs
        v-model="currentTabIndex"
        fill
      >
        <b-tab
          title="Relative"
          active
        >
          <b-form-group>

            <b-form-radio
              v-model="thisXXOperator"
              name="this_xx_selection"
              value="$any"
            >
              Any
            </b-form-radio>

            <b-form-radio
              v-model="thisXXOperator"
              name="this_xx_selection"
              value="$this_month"
            >
              This Month
            </b-form-radio>
            <b-form-radio
              v-model="thisXXOperator"
              name="this_xx_selection"
              value="$this_quarter"
            >
              This quarter
            </b-form-radio>

            <b-form-radio
              v-model="thisXXOperator"
              name="this_xx_selection"
              value="$last_quarter"
            >
              Last quarter
            </b-form-radio>

            <b-form-radio
              v-model="thisXXOperator"
              name="this_xx_selection"
              value="$this_year"
            >
              This Year
            </b-form-radio>

            <div class="form-inline">

              <b-form-radio
                v-model="thisXXOperator"
                name="this_xx_selection"
                value="$last_n_value"
              >
                Last
              </b-form-radio>

              <b-form-input
                v-model="lastNValue"
                :disabled="thisXXOperator !== '$last_n_value'"
                type="number"
                min="1"
                class="last-n-value"
                size="sm"
              />

              <b-form-group>
                <div style="width: 110px">

                  <v-select
                    v-model="timeUnitsSelected"
                    :disabled="thisXXOperator !== '$last_n_value'"
                    :clearable="false"
                    label="title"
                    class="select-size-sm"
                    :options="timeUnitsOptions"
                  />
                </div>
              </b-form-group>

            </div>

          </b-form-group>

        </b-tab>
        <b-tab title="Absolute">

          <b-form-group>
            <v-select
              v-model="absoluteTimeunitsSelected"
              :clearable="false"
              label="title"
              class="select-size-sm"
              :options="absoluteTimeunitsOptions"
            />

          </b-form-group>
          <b-form-group
            label=""
            label-for="time-frame-to"
          >
            <b-form-datepicker
              v-model="absoluteDate1"
              size="sm"
              class="mb-2"
            />
          </b-form-group>

          <b-form-group
            v-show="absoluteTimeunitsSelected.value == '$date_between'"
            label="And"
            label-for="time-frame-from"
          >
            <b-form-datepicker
              v-model="absoluteDate2"
              size="sm"
              class="mb-2"
            />
          </b-form-group>

        </b-tab>
      </b-tabs>
      <div class="timeframe-footer">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <span class="ml-25">{{ currentSelectionLable }} </span>
          </div>
        </b-alert>
        <b-button
          class="right"
          :disabled="!isValid"
          variant="outline-primary"
          @click="applyChanges()"
        >
          Apply
        </b-button>
      </div>

    </b-popover>
    <div :id="'popover-container'+ triggerId" />
  </div></template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import {
  BFormGroup, BFormInput, BButton,
  BTabs, BTab, BFormDatepicker, BPopover, BFormRadio, BAlert,
} from 'bootstrap-vue'

import { v4 as uuidv4 } from 'uuid'

import vSelect from 'vue-select'

const buildDisplayLable = viewModle => {
  let newLable = 'Please select'
  let isValid = true
  switch (viewModle.operator) {
    case '$this_month':
      newLable = 'This month'
      break
    case '$this_quarter':
      newLable = 'This qurter'
      break
    case '$last_quarter':
      newLable = 'Last qurter'
      break
    case '$this_year':
      newLable = 'This year'
      break
    case '$last_days':

      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please set # of days'
        isValid = false
      } else {
        newLable = `Last ${viewModle.operand} days`
      }
      break

    case '$last_weeks':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please set # of weeks'
        isValid = false
      } else {
        newLable = `Last ${viewModle.operand} weeks`
      }
      break

    case '$last_months':

      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please set # of months'
        isValid = false
      } else {
        newLable = `Last ${viewModle.operand} months`
      }
      break

    case '$last_years':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please set # of years'
        isValid = false
      } else {
        newLable = `Last ${viewModle.operand} years`
      }
      break

    case '$date_is':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please select date'
        isValid = false
      } else {
        newLable = 'for date {viewModle.operand}'
      }
      break

    case '$date_after':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please select date'
        isValid = false
      } else {
        newLable = `date after ${viewModle.operand}`
      }
      break

    case '$date_is_after':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please select date'
        isValid = false
      } else {
        newLable = `date ${viewModle.operand} or after`
      }
      break

    case '$date_before':

      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please select date'
        isValid = false
      } else {
        newLable = `date before ${viewModle.operand} `
      }
      break

    case '$date_is_before':
      if (viewModle.operand && viewModle.operand.trim() === '') {
        newLable = 'Please select dates'
        isValid = false
      } else {
        newLable = `date ${viewModle.operand} or before`
      }
      break

    case '$date_between':
      if (!viewModle.operand || viewModle.operand.trim() === '' || !viewModle.operand2 || viewModle.operand2.trim() === '') {
        newLable = 'Please select dates'
        isValid = false
      } else {
        newLable = `date between ${viewModle.operand} and ${viewModle.operand2}`
      }
      break

    default:
    case '$any':
      newLable = 'Any date range'
      break
  }
  return { valid: isValid, lable: newLable }
}

export default defineComponent({
  components: {

    BFormGroup,
    BFormInput,
    BButton,
    BTabs,
    BTab,
    BFormDatepicker,
    BPopover,
    BFormRadio,
    vSelect,
    BAlert,
  },
  props: {
    value: {
      type: Object,
      default() { return { operator: '$this_month', operand: '', operand2: '' } },
      required: false,
    },
  },
  setup(props, context) {
    const displayLable = ref(buildDisplayLable(props.value))
    const lastNValue = ref('')
    const absoluteDate1 = ref('')
    const absoluteDate2 = ref('')
    const currentSelectionLable = ref('Please select...')
    const innerValueModle = ref({ ...props.operand })
    const thisXXOperator = ref('$any')
    const isValid = ref(true)
    const currentTabIndex = ref(0)

    const timeUnitsOptions = [
      { title: 'days', value: '$last_days' },
      { title: 'weeks', value: '$last_weeks' },
      { title: 'months', value: '$last_months' },
      { title: 'years', value: '$last_years' },
    ]

    const absoluteTimeunitsOptions = [
      { title: 'is specific date', value: '$date_is' },
      { title: 'is after', value: '$date_after' },
      { title: 'is specific date or after', value: '$date_is_after' },
      { title: 'is before', value: '$date_before' },
      { title: 'is specific date or before', value: '$date_is_before' },
      { title: 'between', value: '$date_between' },
    ]

    const timeUnitsSelected = ref(timeUnitsOptions[0])
    const absoluteTimeunitsSelected = ref(absoluteTimeunitsOptions[0])

    const updateModleValue = operator => {
      let operand = ''
      let operand2 = ''

      if (['$any', '$last_days', '$last_weeks', '$last_months', '$last_years'].includes(operator)) {
        operand = lastNValue.value
      } else if (['$date_is', '$date_after', '$date_is_after', '$date_before', '$date_is_before'].includes(operator)) {
        operand = absoluteDate1.value
      } else if (operator === '$date_between') {
        operand = absoluteDate1.value
        operand2 = absoluteDate2.value
      }

      const mv = { operator, operand, operand2 }

      const { valid, lable } = buildDisplayLable(mv)
      currentSelectionLable.value = lable
      innerValueModle.value = mv
      isValid.value = valid
    }

    const extractModleValue = mv => {
      if (['$last_days', '$last_weeks', '$last_months', '$last_years'].includes(mv.operator)) {
        thisXXOperator.value = '$last_n_value'
        lastNValue.value = mv.operand
        timeUnitsSelected.value = timeUnitsOptions.find(item => item.value === mv.operator)
        currentTabIndex.value = 0
      } else if (['$any', '$this_month', '$this_quarter', '$this_year', '$last_days'].includes(mv.operator)) {
        thisXXOperator.value = mv.operator
        currentTabIndex.value = 0
      } else if (mv.operator === '$date_between') {
        absoluteDate1.value = mv.operand
        absoluteDate2.value = mv.operand2
        currentTabIndex.value = 1
      } else {
        const absDate = absoluteTimeunitsOptions.find(item => item.value === mv.operator)
        if (absDate) {
          absoluteTimeunitsSelected.value = absDate
          absoluteDate1.value = mv.operand
          currentTabIndex.value = 1
        }
      }
    }

    watch(() => props.value, newValue => {
      const { lable } = buildDisplayLable(newValue)
      displayLable.value = lable
      extractModleValue(newValue)
    }, { immediate: true })

    watch(thisXXOperator, newValue => {
      if (newValue === '$last_n_value') {
        updateModleValue(timeUnitsSelected.value.value)
      } else {
        updateModleValue(newValue)
      }
    })

    watch(timeUnitsSelected, newValue => {
      updateModleValue(newValue.value)
    })

    watch(lastNValue, () => {
      updateModleValue(timeUnitsSelected.value.value)
    })

    watch(absoluteTimeunitsSelected, newValue => {
      updateModleValue(newValue.value)
    })

    watch(absoluteDate1, () => {
      updateModleValue(absoluteTimeunitsSelected.value.value)
    })

    watch(absoluteDate2, () => {
      updateModleValue(absoluteTimeunitsSelected.value.value)
    })

    watch(currentTabIndex, newIndex => {
      if (newIndex === 0) {
        thisXXOperator.value = '$any'
        updateModleValue(thisXXOperator.value)
      } else {
        updateModleValue('$date_is')
        absoluteTimeunitsSelected.value = absoluteTimeunitsOptions.find(item => item.value === '$date_is')
      }
    })

    const syncModleValue = () => {
      context.emit('input', innerValueModle.value)
    }

    return {
      thisXXOperator,
      lastNValue,
      innerValueModle,
      absoluteDate1,
      absoluteDate2,
      timeUnitsSelected,
      timeUnitsOptions,
      currentSelectionLable,
      displayLable,
      absoluteTimeunitsOptions,
      absoluteTimeunitsSelected,
      isValid,
      currentTabIndex,
      syncModleValue,
    }
  },
  data() {
    return {
      triggerId: uuidv4(),

    }
  },
  methods: {
    applyChanges() {
      this.syncModleValue()
      this.$refs.popover.$emit('close')
    },
  },
})
</script>

<style scoped>

 .tabs {
     width: 300px;
 }

 .clx-timeframe {
     max-width: 350px;
 }
 .custom-radio {
     margin-top: 3px;
     margin-bottom: 3px;
     margin-right: 5px;
 }

 .last-n-value {
     width: 80px;
     margin-right: 5px;
 }

 .timeframe-footer {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
 }

.timeframe-footer .alert {
    width: 100%;
    margin-right: 5px;
    margin-bottom: 0px;
}

</style>
