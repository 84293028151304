<template>
  <table>
    <tr
      v-for="(item, index) in localList"
      :key="index"
    >
      <td>
        <b-form-input
          :value="item.key"
          type="text"
          placeholder="Key"
          @input="keyChange(index, $event)"
        />
      </td>
      <td> = </td>
      <td>
        <b-form-input
          :value="item.value"
          type="text"
          placeholder="Value"
          @input="valueChange(index, $event)"
        />
      </td>
      <td>

        <b-button
          v-show="value.length !== 1"
          variant="gradient-danger"
          class="btn-icon"
          @click="removeItem(index)"
        >
          <feather-icon icon="XIcon" />
        </b-button>

      </td>

      <td>
        <b-button
          v-show="index === value.length-1"
          variant="gradient-success"
          class="btn-icon"
          @click="addItem()"
        >
          <feather-icon icon="PlusIcon" />
        </b-button>
      </td>

    </tr>
  </table>

</template>

<script>
import {
  computed, defineComponent, // ref, watch,
} from '@vue/composition-api'
import {
  BFormInput, BButton,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BButton, BFormInput,
  },
  props: {
    value: {
      type: Array,
      default() { return [{ key: '', value: '' }] },
      required: false,
    },
  },

  setup(props, context) {
    // const localList = ref([])

    // const syncToLocal = value => {
    //   if (value && value.length > 0) {
    //     localList.value = value.map(item => ({ key: item.field, value: item.value }))
    //   } else if (localList.value.length === 0) {
    //     localList.value.push({ key: '', value: '' })
    //   }
    // }

    const localList = computed(() => {
      if (!props.value || props.value.length === 0) {
        return [{ key: '', value: '' }]
      }
      return props.value.map(item => ({ key: item.field, value: item.value }))
    })

    // const initialWatch = watch(() => props.value, value => {
    //   console.log('watch cahges', value)
    //   syncToLocal(value)
    // }, { immediate: true })

    const emitChanges = () => {
      const output = localList.value
        // .filter(item => item.key && item.value)
        // .filter(item => item.key.trim() !== '' && item.value.trim() !== '')
        .map(item => ({ field: item.key, value: item.value }))
      context.emit('input', output)
    }

    const addItem = () => {
      // localList.value.push({ key: '', value: '' })
      context.emit('item-added')
      // emitChanges()
    }

    const removeItem = index => {
      // localList.value.splice(index, 1)
      context.emit('item-deleted', index)
      // emitChanges()
    }

    const keyChange = (index, value) => {
      context.emit('item-key-changed', { index, value })
    }

    const valueChange = (index, value) => {
      context.emit('item-value-changed', { index, value })
    }
    return {
      addItem,
      removeItem,
      localList,
      keyChange,
      emitChanges,
      valueChange,
    }
  },
})
</script>

<style scoped>
    td {
        padding: 5px;
    }

</style>
