<template>
  <div>
    <JourneyAddEdit
      v-model="eitedItem"
      @save="saveJourney"
    />
  </div>
</template>

<script>
import JourneyAddEdit from '@/modules/journey/components/JourneyAddEdit.vue'
import store from '@/store'
import { cloneDeep } from 'lodash'
import { createNamespacedHelpers } from 'vuex'

const { mapActions } = createNamespacedHelpers('journey')
let isRouteBusy = false

export default {
  components: {
    JourneyAddEdit,
  },
  beforeRouteEnter(to, from, next) {
    if (isRouteBusy) {
      return
    }
    isRouteBusy = true

    store.dispatch('journey/getJourneyById', to.params.id).then(item => {
      isRouteBusy = false
      next(vm => vm.setCurrentItem(item))
    }, () => {
      isRouteBusy = false
    })
  },
  beforeRouteUpdate(to, from, next) {
    isRouteBusy = true
    store.dispatch('journey/getJourneyById', to.params.id).then(item => {
      isRouteBusy = false
      next(vm => vm.setCurrentItem(item))
    }, () => {
      isRouteBusy = false
    })
  },
  data() {
    return {
      currentJourney: {},
      eitedItem: {},
    }
  },
  methods: {
    setCurrentItem(item) {
      this.currentJourney = item
      this.eitedItem = { name: this.currentJourney.name, stages: cloneDeep(this.currentJourney.stages) }
    },
    saveJourney({ data, close }) {
      const { id } = this.currentJourney
      this.updateJourney({ id, data }).then(() => {
        if (close) {
          this.$router.push({ name: 'second-page' })
        }
      }, () => {
      })
    },
    ...mapActions(['updateJourney']),
  },
}
</script>
